import './Header.css';
import headerImg from '../../../asset/image/home_header_image.png';
import happyFeet from '../../../asset/image/happy_feet_round_text.svg';

function Header() {
  return (
    <div id='Header' className="Header">
      <div className='left'>
        <p className='title'>L'association</p>
        <p className='name'><img src={happyFeet} /></p>
        <p className='punchline'>Dansez comme vous êtes !</p>
        <p className='description'>Nos stages et ateliers vous feront découvrir les danses en ligne, les claquettes ou les percussions corporelles dans une ambiance conviale et décontractée ! Olivier vous partagera sa passion de la danse, du rythme et des percussions à travers sa conception de la pédagogie, basée sur le plaisir, la prise en compte du rythme d’apprentissage et du style de chacun.</p>
      </div>
      <div className='right'>
        <img src={headerImg} className="header_image" alt="Happy Feet" />
      </div>
    </div>
  );
}

export default Header;
