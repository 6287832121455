import './Address.css';
import rightArrow from '../../../../../asset/image/arrow_right_white.svg';

function Address({ header, line1, line2, line3, directionsLink }) {
  return (
    <div className="Address">
      <p className='header'>{header}</p>
      <p className='line_1'>{line1}</p>
      <p className='line_2'>{line2}</p>
      <p className='line_3'>{line3}</p>
      <div className='link'>
        <a href={directionsLink} target="_blank" className='directions_link'>Voir l'itinéraire<img src={rightArrow} color={"white"} className="arrow_right" alt="More" /></a>
      </div>
    </div>
  );
}

export default Address;
