import './Where.css';
import phoneIcon from '../../../asset/image/phone.svg';
import mailIcon from '../../../asset/image/mail.svg';
import fbIcon from '../../../asset/image/fb_f.svg';
import Address from './components/address/Address';

function Where() {
  return (
    <div id='Where' className="Where">
      <div className='title'>
        <p className='title_1'>Où nous</p>
        <p className='title_2'>trouver ?</p>
      </div>
      <div className='content'>
        <div className='contact'>
          <p className='title'>Contact</p>
          <div className='contacts-list'>
            <p className='link number'><a href='tel://+33632702384'><img src={phoneIcon} className="phone" alt="More" />+33 6 32 70 23 84</a></p>
            <p className='link mail'><a href='mailto:obhappyfeet@gmail.com'><img src={mailIcon} className="phone" alt="More" />obhappyfeet@gmail.com</a></p>
            <p className='link fb'><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fbIcon} className="fb" alt="More" />Toute notre actu sur Facebook !</a></p>
          </div>
        </div>
        <div className='addresses'>
          <p className='title'>Adresse des stages</p>
          <div className='content'>
            <Address
              header={"Danses en ligne"}
              line1={<>Salle Jules Verne <span className='light'>(La Pâquelais)</span></>}
              line2={"Rue de la Galtière"}
              line3={"44360 Vigneux de bretagne"}
              directionsLink={"https://goo.gl/maps/LeUMSwiUZYgdAeF98"} />
            <Address
              header={"Claquettes"}
              line1={<>Salle Gesvres et Cens</>}
              line2={"9, Rue du Grand Calvaire"}
              line3={"44360 Vigneux de bretagne"}
              directionsLink={"https://goo.gl/maps/pKNHUv7zKYnbD5ag6"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Where;
