import './LineDanceDetail.css';
import mainLineDanceImage from '../../asset/image/line_dance_main.jpg';
import ActivityDetail from '../common/activity_detail/ActvityDetail';


function LineDanceDetail() {
  return (
    <div className="LineDanceDetail" >
      <ActivityDetail title="Les danses" subtitle="en ligne" image={mainLineDanceImage} contentTitle="Danser ensemble dans la joie et la convivialité" videoUrl="https://www.youtube.com/embed/yaInVpA1fXQ" mainImageThreshold={50}>
        <p>Ne vous méprenez pas, la danse en ligne n’est pas une danse qui s’apprend sur Internet !
          La danse en ligne correspond à <span className='enhanced'>une chorégraphie assez courte</span> (de 16 à 64 temps, qui se répètent sur 2 ou 4 « murs ») adaptée à une musique spécifique
          (ou un type de musique) et qui se danse <span className='enhanced'>sur une ou plusieurs lignes ou en cercle</span>.</p>
        <p>Même s’il existe plusieurs niveaux en danses en ligne, leur succès grandissant auprès d’un public de tous âges et de tous horizons s’explique par leur accessibilité, leur grande diversité et par la joie communicative qu’elle procure du fait de danser seul mais dans un même mouvement collectif.</p>
        <p>Olivier, de par sa jovialité, son énergie et sa passion pour la transmission et pour les danses du monde entier vous emportera dans <span className='enhanced'>un univers joyeux et coloré</span>.Voici une liste (non exhaustive) des styles de danses en ligne qui vous seront proposées (Carquefou, Vigneux de Bretagne / La Paquelais, La Chapelle-sur-Erdre) :</p>
        <ul>
          <li>Madison(s)</li>
          <li>Kuduro(s)</li>
          <li>Country</li>
          <li>Sirtaki</li>
          <li>Cumbia</li>
          <li>Salsa</li>
          <li>Funk</li>
          <li>Irish dance</li>
          <li>Bollywood</li>
          <li>Afro</li>
          <li>Line dance (chorégraphies adaptées à des musiques actuelles)</li>
          <li>etc…</li>
        </ul>
      </ActivityDetail>
    </div>
  );
}

export default LineDanceDetail;
