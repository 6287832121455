import { useEffect, useState } from 'react';
import NavBar from '../common/navbar/NavBar';
import Activities from './activities/Activities';
import Header from './header/Header';
import './Home.css';
import More from './more/More';
import Where from './where/Where';
import triangle1 from '../../asset/image/triangle_1.svg';
import triangle2 from '../../asset/image/triangle_2.svg';
import triangle3 from '../../asset/image/triangle_3.svg';
import triangle4 from '../../asset/image/triangle_4.svg';
import whiteCircle from '../../asset/image/white_circle.svg';
import linePattern from '../../asset/image/line_pattern_bg_3.png';
import ParallaxImage from '../common/parallax_image/ParallaxImage';

function Home() {
  const [delta, setDelta] = useState(0);
  const [navbarFixed, setNavbarFixed] = useState(true);
  const [navbarBackground, setNavbarBackground] = useState(false);

  var dlt = 0;

  useEffect(() => {
    const handleScroll = event => {
      let newDelta = window.scrollY / 4;
      let newNavbarBackground = newDelta > 20;
      let newNavbarFixed = newDelta < dlt || newDelta < 10;
      dlt = newDelta;
      setNavbarFixed(newNavbarFixed);
      setNavbarBackground(newNavbarBackground);
      setDelta(newDelta * 2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="Home">
      <div className="inner">
        <div className="inside">
          <div>
            <ParallaxImage id="triangle1" image={triangle1} properties={{ top: (-20 - delta / 2) + "px" }} />
            <ParallaxImage id="triangle2" desktopOnly={true} image={linePattern} properties={{ top: (310 - delta / 3) + "px", left: "0%" }} />
            <ParallaxImage id="triangle3" desktopOnly={true} image={whiteCircle} properties={{ top: (250 - delta / 2) + "px", left: "10%" }} />
            <ParallaxImage id="triangle4" image={triangle3} properties={{ zIndex: "0", top: (850 - delta / 2) + "px" }} />
            <ParallaxImage id="triangle5" image={triangle2} properties={{ zIndex: "0", top: (2020 - delta * 2) + "px", left: "7%" }} />
            <ParallaxImage id="triangle6" image={triangle2} properties={{ zIndex: "20", top: (20 - delta) + "px" }} />
            <ParallaxImage id="triangle7" image={triangle3} properties={{ zIndex: "20", top: (530 - delta * 2) + "px", right: "20%" }} />
            <ParallaxImage id="triangle8" image={triangle4} properties={{ zIndex: "20", top: (3430 - delta * 2) + "px", left: "28%" }} />
            <ParallaxImage id="triangle9" image={triangle2} properties={{ zIndex: "20", top: (2300 - delta) + "px", right: "50%" }} />
          </div>
          <NavBar navbarFixed={navbarFixed} navbarBackground={navbarBackground} />
          <Header />
          <Activities delta={delta} />
          <More />
          <Where />
        </div>
      </div>
    </div>
  );
}

export default Home;
