import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './view/home/Home';
import reportWebVitals from './tech/reportWebVitals';
import LineDanceDetail from './view/line_dance_detail/LineDanceDetail';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import TapDanceDetail from './view/tap_dance_detail/TapDanceDetail';
import BodyTapDetail from './view/body_tap_detail/BodyTapDetail';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "line",
    element: <LineDanceDetail />,
  },
  {
    path: "tap",
    element: <TapDanceDetail />,
  },
  {
    path: "body",
    element: <BodyTapDetail />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
