import './NavBar.css';
import logo from '../../../asset/image/logo.svg';
import logoLine from '../../../asset/image/logo_line.svg';
import arrowDown from '../../../asset/image/arrow_down.svg';
import fb from '../../../asset/image/fb.svg';
import { useEffect, useState } from 'react';

function NavBar({ navbarFixed, navbarBackground }) {
  const [isShownDropdown, setIsShownDropdown] = useState(false);

  return (
    <div className={"NavBar" + (navbarFixed ? " fixed" : "") + (navbarBackground ? " opaque-background" : "")}>
      <div className='inner mobileHidden'>
        <div className='left'><a href='/'><img src={logo} className="App-logo" alt="logo" /></a></div>
        <nav className='links'>
          <ul>
            <li><a href='/#Header'>L'association</a></li>
            <li onMouseEnter={() => setIsShownDropdown(true)} onMouseLeave={() => setIsShownDropdown(false)} className={'activities-dropdown' + (isShownDropdown ? " open" : "")}>
              <span className='activities-dropdown-title'>Nos activités<img src={arrowDown} className="arrow-down" alt="" /></span>
              <ul className='activities-dropdown_list'>
                <li><a href="https://ahf.ceni.digital/line">Danses en ligne</a></li>
                <li><a href="https://ahf.ceni.digital/tap">Claquettes</a></li>
                <li><a href="https://ahf.ceni.digital/body">Percussions corporelles</a></li>
              </ul>
            </li>
            <li><a href='/#Where'>Contactez nous</a></li>
            <li><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fb} alt="" /></a></li>
          </ul>
        </nav>
      </div>
      <div className='inner mobileOnly'>
        <div className='left'><a href='/'><img src={logoLine} className="App-logo" alt="logo" /></a></div>
        <nav className='links'>
          <ul>
            <li><a href='https://www.facebook.com/olivier.happyfeet' target="_blank"><img src={fb} alt="" /></a></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default NavBar;
