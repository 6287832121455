import './TapDanceDetail.css';
import mainTapDanceImage from '../../asset/image/tap_dance_main.jpg';
import ActivityDetail from '../common/activity_detail/ActvityDetail';


function TapDanceDetail() {
  return (
    <div className="TapDanceDetail" >
      <ActivityDetail title="Les" subtitle="claquettes" image={mainTapDanceImage} contentTitle="Les claquettes sont tout sauf rétro!" videoUrl="https://www.youtube.com/embed/Gul3REAAm1w" mainImageThreshold={-10}>
        <p>L'association Happy Feet vous offre l'opportunité de découvrir les claquettes autrement ! Venez danser sur des rythmes inattendus et nouveaux, aux multiples influences (jazz, swing, funky, afro, reggae, salsa..) sans perdre de vue que la tapdance est avant tout un plaisir et une détente !</p>
        <p>Les stages et cours ont lieu dans la région Nantaise (Vigneux de Bretagne et la Chapelle-sur-Erdre), et sont ouverts à tous, quel que soit votre niveau.</p>
      </ActivityDetail>
    </div>
  );
}

export default TapDanceDetail;
