import './Activity.css';
import rightArrow from '../../../../../asset/image/arrow_right.svg';
import blueRectangle from '../../../../../asset/image/blue_rectangle.svg';

function Activity({image, header, title, description, link}) {
  return (
    <div className="Activity">
      <p className='image'><img src={image} className="blue_rectangle" alt={title} /></p>
      <p className='title_1'><img src={blueRectangle} className="blue_rectangle" alt="More" />{header}</p>
      <p className='title_2'>{title}</p>
      <p className='description'>{description}</p>
      <a className='action' href={link}><img src={rightArrow} className="arrow_right" alt="More" />En savoir plus</a>
    </div>
  );
}

export default Activity;
